/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";

export default {

  async saveBarcode(payload) {
    const response = await Api().post(`api/dashboard/barcode/`, payload);
    return response;
  },

  async getAllBarcode(payload) {
    // Dynamically build the query string
    const queryParams = new URLSearchParams();

    if (payload?.status_code) queryParams.append("search", payload.status_code);
    if (payload?.start_date) queryParams.append("start_date", payload.start_date);
    if (payload?.end_date) queryParams.append("end_date", payload.end_date);
    if (payload?.created_by) queryParams.append("created_by", payload.created_by);
    if (payload?.updated_by) queryParams.append("updated_by", payload.updated_by);
    if (payload?.barcode_is_active !== undefined) queryParams.append("barcode_is_active", payload.barcode_is_active);
    if (payload?.barcode_is_deleted !== undefined) queryParams.append("barcode_is_deleted", payload.barcode_is_deleted);
    if (payload?.page) queryParams.append("page", payload.page);
    if (payload?.pageSize) queryParams.append("pageSize", payload.pageSize);
    if (payload?.isAll !== undefined) queryParams.append("isAll", payload.isAll);
    if (payload?.isSelectedColumn !== undefined) queryParams.append("isSelectedColumn", payload.isSelectedColumn);

    const queryString = queryParams.toString();
    const response = await Api().get(`api/dashboard/barcode/?${queryString}`);
    return response;
  },
  async getSingleBarcode(payload) {
    const response = await Api().get(`api/dashboard/barcode/${payload}`);
    return response;
  },
  async RemoveBarcode(payload) {
    const response = await Api().post(`api/dashboard/barcode/remove`, payload);
    return response;
  },
  async updateBarcodeStatus(payload) {
    const response = await Api().post(`api/dashboard/barcode/status/`, payload);
    return response;
  },

  async barcodeExcelImport(payload) {
    const response = await Api().post(`api/dashboard/barcode/import`, payload);
    return response;
  },

  async courierExcelImport(payload) {
    const response = await Api().post(`api/dashboard/barcode/courier-import`, payload);
    return response;
  },
  async regionExcelImport(payload) {
    const response = await Api().post(`api/dashboard/barcode/region-import`, payload);
    return response;
  },
  async regionZoneStateExcelImport(payload) {
    const response = await Api().post(`api/dashboard/barcode/region-zone-state-import`, payload);
    return response;
  },
  async userExcelImport(payload) {
    const response = await Api().post(`api/dashboard/barcode/user-import`, payload);
    return response;
  },

};
