import * as Yup from "yup";

export const countrySchema = Yup.object({
  country_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Country Name"),
  country_code: Yup.string().required("please Enter 3 character Country code"),
  country_isd_code: Yup.number()
    .min(1)
    .max(999)
    .required("please Enter the Country Calling code"),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
});

export const companySchema = Yup.object({
  company_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Company Name"),
  company_logo: Yup.mixed().test(
    "company_logo",
    "Please Choose a Company Logo",
    function (value) {
      const company_logo = this.resolve(Yup.ref("company_logo")); // Replace with actual path field name
      if (!company_logo && !value) {
        return this.createError({
          message: "Company Logo is required",
          path: "company_logo",
        });
      }

      return true;
    }
  ),
  company_cin: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company CIN"),
  company_gstno: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company GSTIN"),
  company_registered_office_add: Yup.string()
    .min(2)
    .max(100)
    .required("Please Enter the Company Reg Office Address"),
  company_corporate_office_add: Yup.string()
    .min(2)
    .max(100)
    .required("Please Enter the Company Corp Office Address"),
  company_panno: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Pan Card Number"),
  company_telephone_no: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Telephone Number"),
  company_bank_account_no: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Bank A/c Number"),
  company_bank_account_name: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Bank A/c Name"),
  company_bank_name: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Bank Name"),
  company_ifsc_code: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Bank IFSC Code"),
  company_swift_code: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Swift Code"),
  company_email: Yup.string()
    .min(2)
    .max(100)
    .required("Please Enter the Company Email"),
  company_branch_name: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Bank Branch Name"),
  company_website: Yup.string()
    .min(2)
    .max(20)
    .required("Please Enter the Company Webiste"),
  company_pincode: Yup.string()
    .min(5)
    .max(10)
    .required("Please Enter the Company Pincode"),
  company_legal_entity_type: Yup.string().required(
    "Please Enter the Company Entity Type"
  ),
  company_registration_date: Yup.date()
    .nullable()
    .required("Please Enter Company Registration Date"),
  company_vat_no: Yup.string()
    .min(2)
    .max(10)
    .required("Please Enter the Company Vat Number"),
  company_tax_registration_no: Yup.string()
    .min(2)
    .max(10)
    .required("Please Enter the Company Tax Registration Number"),
});

export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),

  fk_state_id: Yup.string().required("Please select state"),
});

export const roleSchema = Yup.object({
  role_name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
  role_commission_per: Yup.number()
    .min(1)
    .required("Please Enter the Commission Percentage"),
});

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  // conf_password: Yup.string()
  // 	.required()
  // 	.oneOf([Yup.ref("user_password"), null], "Password must match"),
  // user_location_id: Yup.string().required("Please Enter the Location ID"),
  user_role: Yup.string().required("Please Enter the Role"),
});

export const profileSchema = Yup.object({
  user_password: Yup.string()
    .min(6)
    .nullable()
    .required("Please Enter the Password"),
  conf_password: Yup.string()
    .nullable()
    .required()
    .oneOf([Yup.ref("user_password"), null], "Password must match"),
});

export const magazineSchema = Yup.object({
  magazine_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Magazine Name"),
  mm_code: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Magazine Code"),
  // magazine_gap: Yup.number().required("Please Enter the Interval"),

  magazine_banner: Yup.mixed().test(
    "magazine_banner",
    "Please Choose a Magazine Banner",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("magazine_banner")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: "Magazine Banner is required",
          path: "magazine_banner",
        });
      }

      return true;
    }
  ),
});
export const magazineIssueSchema = Yup.object({
  issue_name: Yup.string().min(3).max(25).required("Please Enter the Name"),
  issue_month: Yup.date().nullable().required("Month is required"),
  issue_date: Yup.date().nullable().required("Date is required"),
  // issue_week: Yup.date()
  //   .nullable()
  //   .required('Week is required'),
  // issue_weekday: Yup.date()
  //   .nullable()
  //   .required('Weekdat is required'),
  issue_price: Yup.number().required("Please Enter the Price"),
  issue_cover: Yup.mixed().test(
    "picture",
    "Please Choose a picture",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("issue_cover")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: " picture is required",
          path: "issue_cover",
        });
      }
      return true;
    }
  ),
});

export const skuSchema = Yup.object({
  sku_code: Yup.string().min(3).max(25).required("Please Enter the Name"),
  sku_type: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  sku_total_price: Yup.number().required("Please Enter the price"),
  sku_description: Yup.string().min(3).required("Please Enter the description"),
  sku_picture: Yup.mixed().test(
    "picture",
    "Please Choose a picture",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("sku_picture")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: " picture is required",
          path: "sku_picture",
        });
      }
      return true;
    }
  ),
});

export const productSchema = Yup.object({
  product_name: Yup.string().min(3).max(25).required("Please Enter the Name"),
  // product_type: Yup.array().of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ),
  product_price: Yup.number().required("Please Enter the price"),
  product_qty: Yup.number().required("Please Enter the QTY"),
  // product_discount: Yup.number().required("Please Enter the discount"),
  // product_no_of_issue: Yup.number().required("Please Enter the issue no"),
  // product_image: Yup.mixed().test(
  //   "picture",
  //   "Please Choose a picture",
  //   function (value) {
  //     const bannerPath = this.resolve(Yup.ref("product_poster")); // Replace with actual path field name
  //     if (!bannerPath && !value) {
  //       return this.createError({
  //         message: " picture is required",
  //         path: "product_poster",
  //       });
  //     }
  //     return true;
  //   }
  // ),

  product_image: Yup.mixed().test(
    "product_image",
    "Please Choose a Product Poster",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("product_image")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: "Product Poster is required",
          path: "product_image",
        });
      }

      return true;
    }
  ),
});
export const orderSchema = Yup.object({
  customer_username: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Customer Name"),
  // customer_gender: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       label: Yup.string().required(),
  //       value: Yup.string().required(),
  //     })
  //   ).nullable()
  //   .required('Gender is required'),
  // fk_country_id: Yup.array().of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ).nullable().required('Country is required'),
  // payment_type: Yup.array().of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ).nullable().required('Payment method is required'),
  customer_dob: Yup.date().nullable().required("Please Enter the DOB"),
  customer_email: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Email"),
  customer_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("please Enter the Mobile"),

  customer_address: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Address"),
  state_name: Yup.string().min(2).max(100).required("please Enter the State"),
  city_name: Yup.string().min(2).max(100).required("please Enter the City"),
  pincode: Yup.string().min(5).max(10).required("please Enter the Pincode"),
  trans_card_id: Yup.string()
    .min(10)
    .max(10)
    .required("please Enter the Doc No"),
  trans_amount: Yup.number().required("please Enter the Amount"),
  trans_bank_date: Yup.string()
    .min(10)
    .max(10)
    .required("please Enter the Doc Date"),
  trans_bank: Yup.string()
    .min(2)
    .max(10)
    .required("please Enter the Bank Name"),
  trans_branch_name: Yup.string()
    .min(2)
    .max(10)
    .required("please Enter the Branch Name"),
});
export const voucherSchema = Yup.object({
  voucher_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Voucher Name"),
  // voucher_code: Yup.string()
  //   .min(5)
  //   .max(50)
  //   .required("please Enter the Voucher Code"),
  voucher_value: Yup.string()
    .min(1)
    .max(100)
    .required("please Enter the Voucher Value"),
  minimum_transaction: Yup.string()
    .min(1)
    .max(100)
    .required("please Enter the Minimum Transaction"),
  max_discount: Yup.string()
    .min(1)
    .max(100)
    .required("please Enter the Max Discount"),
  voucher_per_user_limit: Yup.string()
    .min(1)
    .max(100)
    .required("please Enter the Per User Limit"),
  voucher_max_redeems: Yup.string()
    .min(1)
    .max(100)
    .required("please Enter the Max Redeems"),
  // sku_applicable_on: Yup.string().min(1).max(100).required("please Enter the Max Redeems"),
  voucher_start_date: Yup.date()
    .nullable()
    .required("Please Enter the Start Date"),
  voucher_end_date: Yup.date().nullable().required("Please Enter the End Date"),
});
export const customerSchema = Yup.object({
  customer_first_name: Yup.string()
    .min(3)
    .max(100)
    .required("Please Enter the Customer First Name")
    .nullable()
    .typeError("Please Enter the Customer First Name"),
  customer_last_name: Yup.string()
    .min(3)
    .max(100)
    .required("Please Enter the Customer Last Name")
    .nullable()
    .typeError("Please Enter the Customer Last Name"),
  customer_username: Yup.string()
    .min(1)
    .max(100)
    .required("Please Enter the Customer Username")
    .nullable()
    .typeError("Please Enter the Customer Username"),
  customer_email: Yup.string()
    .min(2)
    .max(100)
    .required("Please Enter the email")
    .nullable()
    .typeError("Please Enter the email"),
  customer_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number")
    .nullable()
    .typeError("Please Enter the Mobile Number"),
  // customer_address: Yup.string().min(2).max(100).required("please Enter the Customer Address"),
  customer_legal_proof: Yup.string()
    .min(1)
    .max(100)
    .required("Please Enter the Customer Legal Proof")
    .nullable()
    .typeError("Please Enter the Customer Legal Proof"),
  customer_occupation: Yup.string()
    .min(1)
    .max(100)
    .required("Please Enter the Customer Occupation")
    .nullable()
    .typeError("Please Enter the Customer Occupation"),
  customer_dob: Yup.date()
    .nullable()
    .required("Please Enter the Date Of Birth")
    .typeError("Please Enter the Date Of Birth"),
  customer_designation: Yup.string()
    .min(1)
    .max(100)
    .required("Please Enter the Customer Designation")
    .nullable()
    .typeError("Please Enter the Customer Designation"),
  customer_organisation: Yup.string()
    .min(1)
    .max(100)
    .required("Please Enter the Customer Organisation")
    .nullable()
    .typeError("Please Enter the Customer Organisation"),
});
export const customerAddressSchema = Yup.object({
  ca_address: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Customer Address"),
  ca_landmark: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Customer Landmark"),
  pincode: Yup.string().min(2).max(10).required("please Enter the Pincode"),
  // city_name: Yup.string().min(2).max(10).required("please Enter the City Name"),
  // state_name: Yup.string().min(2).max(10).required("please Enter the State"),
  // country_name: Yup.string().min(2).max(10).required("please Enter the Country Name"),
});

export const vendorSchema = Yup.object({
  vendor_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Vendor Name"),
  // vendor_first_name: Yup.string()
  // .min(3)
  // .max(25)
  // .required("Please Enter the First Name"),
  // vendor_last_name: Yup.string()
  // .min(2)
  // .max(25)
  // .required("Please Enter the Last Name"),
  vendor_email: Yup.string().email().required("Please Enter the Email address"),
  vendor_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  // fk_country_id: Yup.string().required("Please Select the Country"),
  // fk_state_id: Yup.string().required("Please Select the State"),
  // fk_city_id: Yup.string().required("Please Select the City"),
  vendor_address: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Vendor Address"),
  vendor_pincode: Yup.string()
    .min(3)
    .max(8)
    .required("Please Enter the Pincode"),
});

export const courierSchema = Yup.object({
  delivery_mode: Yup.string().required("Please Enter Delivery Mode"),
  short_code: Yup.string().required("Please Enter Code"),
  location: Yup.string().required("Please Enter Location"),

  // fk_city_id: Yup.string().required("Please select City"),
  // pin_code_id: Yup.string().required("Please select Pincode"),
});

export const gstMasterSchema = Yup.object({
  gst_name: Yup.string()
    .min(3)
    .max(100)
    .required("Please Enter the GST Name")
    .nullable()
    .typeError("Please Enter the GST Name"),
  gst_rate: Yup.string().min(1).max(2).required("Please Enter the GST Rate"),
});
