import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { subscription_dispatch_columns } from '../../../components/tables/tableheader';
import { toast } from "react-toast";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { convertToAnotherTimezone, calculateAge } from '../../../helper/commonHelper';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import PrintAddress from './PrintAddress';

const SubsIntervalDispatch = () => {
    const [loading, setLoading] = useState(false);
    const [getSubsIntervalDispatch, setGetSubsIntervalDispatch] = useState([])
    const [dayList, setDayList] = useState([])
    const navigate = useNavigate();
    const months = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1; i <= 12; i++) {
        const monthValue = i < 10 ? `0${i}` : `${i}`;
        const monthLabel = new Date(currentYear, i - 1, 1).toLocaleString('en-US', { month: 'long' });
        months.push({ label: monthLabel, value: monthValue });
    }

    // const daysList = [];
    // for (let i = 1; i <= 31; i++) {
    //     const dayValue = i;
    //     // const dayValue = i < 10 ? `0${i}` : `${i}`;
    //     const dayLabel = `${dayValue} Day`;
    //     daysList.push({ label: dayLabel, value: dayValue });
    // }
    const pages = [{ title: "Subscriptin Dispatch List", href: "/subscription-dispatch", module_id: 10 }];
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        subinm_id: null,
    });

    const [allowedActions, setAllowedActions] = useState([]);
    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("user_permissions"));
        if (permissions && window.location.pathname) {
            const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
            const actions = getAllowedActions(permissions, window.location.pathname);
            setAllowedActions(actions);
        }
    }, []);
    const handleExport = (excel_type) => {

        if (getSubsIntervalDispatch?.length > 0) {
            let newSheetdata = [];
            getSubsIntervalDispatch.map((obj, index) => {
                const newData = {
                    SN: parseInt(index) + 1,
                    subs_id: obj?.subscription_relation?.subs_id || '-',
                    sku_code: obj?.subinm_skus_relation?.sku_code || '-',
                    mm_name: obj?.subinm_master_magazines_relation?.mm_name || '-',
                    selectedDate: moment(obj?.selectedDate).format("DD-MM-YYYY") || '-',
                    subs_start_date: moment(obj?.subscription_relation?.subs_start_date).format("DD-MM-YYYY") || '-',
                    subs_end_date: moment(obj?.subscription_relation?.subs_end_date).format("DD-MM-YYYY") || '-',
                    subs_fk_ord_id: obj?.subscription_relation?.subs_fk_ord_id || '-',
                    duration: obj?.duration || '-',
                    track_no: obj?.track_no || '-',
                    shipped_date: moment(obj?.shipped_date).format("DD-MM-YYYY") || '-',
                    subinm_dispatch_is_status: obj?.subinm_dispatch_is_status == true ? 'Dispatched' : 'Not Dispatched' || '-',
                    customer_id: obj?.subinm_customer_relation?.customer_id || '-',
                    customer_username: obj?.subinm_m_order_relation?.ord_customer_name ? obj?.subinm_m_order_relation?.ord_customer_name : obj?.subinm_customer_relation?.customer_username || '-',
                    customer_email: obj?.subinm_customer_relation?.customer_email || '-',
                    customer_mobile: obj?.subinm_customer_relation?.customer_mobile || '-',
                    customer_dob: moment(obj?.subinm_customer_relation?.customer_dob).format("DD-MM-YYYY") || '-',
                    Age: calculateAge(moment(obj?.subinm_customer_relation?.customer_dob).format("YYYY-MM-DD")) || '0',
                    Country: obj?.subinm_m_order_relation?.ord_customer_state_name || '-',
                    State: obj?.subinm_m_order_relation?.ord_customer_state_name || '-',
                    City: obj?.subinm_m_order_relation?.ord_customer_city_name || '-',
                    Address: obj?.subinm_m_order_relation?.ord_customer_customer_address || '-',
                    Pincode: obj?.subinm_m_order_relation?.ord_customer_pincode || '-',
                    Gender: obj?.subinm_customer_relation?.customer_gender || '-',
                    Occupation: obj?.subinm_customer_relation?.customer_occupation || '-',
                    Designation: obj?.subinm_customer_relation?.customer_designation || '-',
                    Status: obj?.subinm_is_active == true ? 'Active' : 'In-Active' || '-',
                }
                newSheetdata.push(newData);
            });
            let currentDate = new Date();
            let fileName = 'Subscription_Dispatch_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['SN', 'Sub ID', 'SKU`s Name', 'Magazine Name', 'Issues Date', 'Sub Start Date', 'Sub End Date', 'Order Id', 'Duration', 'Tracking Code', 'Dispatch Date', 'Dispatch Status', 'Customer ID', 'Customer Name', 'Customer Email', 'Customer Phone', 'Customer DOB', 'Customer Age', 'Country', 'State', 'City', 'Address', 'Pincode', 'Gender', 'Occupation', 'Designation', 'Status']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }

    // Filter Values Start
    let filterInitialValues = {
        filterFrom: "",
        filterTo: "",
        filterMonth: { label: "", value: "" },
        filterDay: { label: "", value: "" },
        filterCustomerName: "",
        filterCustomerEmail: "",
        filterCustomerMobile: "",
        filterSubscriptionId: "",
        filterOrderId: "",
        filterMagazineId: { label: "", value: "" },
        filterMagazineIssueId: { label: "", value: "" },
        filterStatus: "",
        filterTrackingCode: "",
        filterTrackingDate: "",
        filterDispatchStatus: "",
    };
    let [filterSubscription, setFilterSubscription] = useState(filterInitialValues);
    const [filterFlag, setFilterFlag] = useState(true);
    const [getAllMagazine, setGetAllMagazine] = useState([])
    const [getAllMagazinesIssue, setGetAllMagazinesIssue] = useState([]);
    const animatedComponents = makeAnimated();
    // Function to handle to CLear filter values
    const onFilterClear = () => {
        setDayList([])
        setIsSearched(false)
        setGetSubsIntervalDispatch([])
        filterSubscription.filterFlag = false;
        setFilterSubscription(filterInitialValues);
        // getAllSubscriptionDispatchList(3);
    }
    // Function to handle changes in filter values
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterSubscription({ ...filterSubscription, [name]: value });
    };
    const handleSelectFilterChange = (selectedOption, name) => {
        console.log("selectedOption=>", selectedOption)
        const { label, value } = selectedOption || {};
        setFilterSubscription({ ...filterSubscription, [name]: selectedOption });
    };
    const onFilter = () => {
        if (checkObjectValues(filterSubscription)) {
            setIsSearched(true)
            // setFilterSubscription({ ...filterSubscription, filterFlag: true });
            filterSubscription.filterFlag = true;
            console.log("filterSubscription=>", filterSubscription)
            getAllSubscriptionDispatchList(3);
        } else {
            toast.error("Please Select Alteast One Filter!");
        }
    }
    const getMagazines = () => {
        MasterServices.getAllMagazine(1).then((res) => {
            const { data, status } = res.data
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    ...ele,
                    label: ele.mm_name,
                    value: ele.mm_id,
                }))
                setGetAllMagazine(newArray);
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const getMagazineIssues = (e) => {
        MasterServices.getAllMagazineIssues(e.value).then((res) => {
            const { data, status } = res.data;
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    value: ele.mi_id,
                    label: ele.mi_name
                }))
                setGetAllMagazinesIssue(newArray)
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    // Filter Values End

    useEffect(() => {
        getMagazines();
        // getAllSubscriptionDispatchList(3);
    }, []);
    const getAllSubscriptionDispatchList = async () => {
        setLoading(true)
        let payload = {
            status_code: 3,
            customer_id: 0,
            filterSubscription: filterSubscription,
        }
        const response = await MasterServices.getAllSubscriptionDispatch(payload);
        console.log("response.data=>", response.data);
        if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
                setGetSubsIntervalDispatch(response?.data?.data);
            } else {
                setGetSubsIntervalDispatch([]);
            }
            setLoading(false)
        }
    };
    const onDeleteOpen = (subinm_id) => {
        setConfirmationModal({ subinm_id, status: true });
    }

    const onDeleteItem = (subinm_id) => {
        console.log("subinm_id", subinm_id)
        MasterServices.deleteSubscriptionDispatch({ subinm_id })
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ subinm_id: null, status: false });
                    getAllSubscriptionDispatchList(3);
                    navigate("/subscription/");
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const onStatusUpdate = (subinm_id, subinm_is_active) => {
        let payload = {
            table_name: "subscription_interval_map",
            table_field: "subinm_id",
            table_field_val: subinm_id,
            table_status_field: "subinm_is_active",
            table_status_val: subinm_is_active ? false : true,
            table_text: "Subscription Tracking",
            deleted_by_key: "subinm_updated_by",
            delete_active_txt: subinm_is_active ? " Deactivated" : " Activated"
        }
        MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data
            if (status) {
                getAllSubscriptionDispatchList(3);
                toast.success(message);
            } else {
                toast.error(message)
            }
        })
    }

    const handleSaveTrackNoDispatch = (originalCell, newTrackNoValue, ser_id, type) => {
        console.log("DIspatch data=>", originalCell, type);
        let payload = {
            subinm_id: originalCell?.subinm_id,
            shipped_date: type == 'ShippedDate' ? newTrackNoValue : originalCell?.shipped_date ? originalCell?.shipped_date : null,
            track_no: type == 'TrackNo' ? newTrackNoValue : originalCell?.track_no ? originalCell?.track_no : null,
        };
        MasterServices.saveSubscriptionDispatch(payload)
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    // setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ subinm_id: null, status: false });
                    getAllSubscriptionDispatchList(3);
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const handleDispatch = () => {
        if (getSubsIntervalDispatch?.length > 0) {
            // const dispatchIds = getSubsIntervalDispatch
            //     .map((item) => item.subinm_id && item.subinm_dispatch_is_status==false) // Extract dispatch_id
            //   .join(", "); // Join them as a string
            const dispatchIds = getSubsIntervalDispatch
                .filter((item) => item.subinm_dispatch_is_status === false) // Filter where psd_is_dispatched is false
                .map((item) => item.subinm_id);
            let payload = {
                subinm_id: dispatchIds,
                shipped_date: null,
                track_no: null,
            };
            MasterServices.saveMultipleSubscriptionDispatch(payload)
                .then((response) => {
                    const { data, status } = response;
                    if (data.status) {
                        // setLoading(true)
                        toast.success(data.message)
                        setConfirmationModal({ subinm_id: null, status: false });
                        getAllSubscriptionDispatchList(3);
                    } else {
                        toast.error(data.message)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error("Sorry Fatal Error!")
                    setLoading(false)
                });
        } else {
            toast.error("Data Not Found!");
        }
    }
    // You can define a function to increment the month value

    const [isSearched, setIsSearched] = useState(false)

    function checkObjectValues(obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === "object") {
                    if (checkObjectValues(obj[key])) {
                        return true;
                    }
                } else if (obj[key] !== "") {
                    return true;
                }
            }
        }
        return false;
    }

    const [showPrintAddress, setShowPrintAddress] = useState(false);
    const [printAddressData, setPrintAddressData] = useState();
    const printAddressDataHandler = (data) => {
        setPrintAddressData(data)
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteItem(confirmationModal.subinm_id)}
            />
            <Breadcrumb pages={pages} />


            {filterFlag && (
                <div className="px-2 py-2 pb-5 mb-10 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
                    <button
                        type="button"
                        className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
                        onClick={() => { setFilterFlag(false); onFilterClear(); }}
                    >
                        X
                    </button>
                    <div className="space-y-6 sm:space-y-5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine
                                </label>
                                <Select
                                    name="filterMagazineId"
                                    id="filterMagazineId"
                                    placeholder="Select Magazine *"
                                    onChange={(e) => { e?.mm_interval_type == 'Day' ? setDayList(e?.mm_date_interval) : setDayList([]); console.log("PS=>", e); getMagazineIssues(e); handleSelectFilterChange(e, 'filterMagazineId') }}
                                    value={filterSubscription.filterMagazineId}
                                    options={getAllMagazine}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineIssueId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine Issues
                                </label>
                                <Select
                                    name="filterMagazineIssueId"
                                    id="filterMagazineIssueId"
                                    placeholder="Select Magazine Issues *"
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterMagazineIssueId') }}
                                    value={filterSubscription.filterMagazineIssueId}
                                    options={getAllMagazinesIssue}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="Months" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Months
                                </label>
                                <Select
                                    name="filterMonth"
                                    id="filterMonth"
                                    placeholder="Select Month *"
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterMonth') }}
                                    value={filterSubscription.filterMonth}
                                    options={months}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </div>
                            {dayList?.length > 0 ? (<div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="Day" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Days
                                </label>
                                <Select
                                    name="filterDay"
                                    id="filterDay"
                                    placeholder="Select Days *"
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterDay') }}
                                    value={filterSubscription.filterDay}
                                    options={dayList}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </div>) : (<></>)}


                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Name
                                </label>
                                <input
                                    type="text"
                                    name="filterCustomerName"
                                    id="filterCustomerName"
                                    value={filterSubscription.filterCustomerName}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerEmail" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Email
                                </label>
                                <input
                                    type="email"
                                    name="filterCustomerEmail"
                                    id="filterCustomerEmail"
                                    value={filterSubscription.filterCustomerEmail}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerMobile" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Mobile
                                </label>
                                <input
                                    type="number"
                                    name="filterCustomerMobile"
                                    id="filterCustomerMobile"
                                    value={filterSubscription.filterCustomerMobile}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            {/* <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterFrom" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Start Date
                                </label>
                                <input
                                    type="date"
                                    name="filterFrom"
                                    id="filterFrom"
                                    value={filterSubscription.filterFrom}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterTo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    name="filterTo"
                                    id="filterTo"
                                    value={filterSubscription.filterTo}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div> */}
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterSubscriptionId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Subscription ID
                                </label>
                                <input
                                    type="number"
                                    name="filterSubscriptionId"
                                    id="filterSubscriptionId"
                                    value={filterSubscription.filterSubscriptionId}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterOrderId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Order ID
                                </label>
                                <input
                                    type="number"
                                    name="filterOrderId"
                                    id="filterOrderId"
                                    value={filterSubscription.filterOrderId}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterTrackingCode" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Tracking Code
                                </label>
                                <input
                                    type="text"
                                    name="filterTrackingCode"
                                    id="filterTrackingCode"
                                    value={filterSubscription.filterTrackingCode}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterTrackingDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Tracking Date
                                </label>
                                <input
                                    type="date"
                                    name="filterTrackingDate"
                                    id="filterTrackingDate"
                                    value={filterSubscription.filterTrackingDate}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterDispatchStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Dispatch Status
                                </label>
                                <select
                                    name="filterDispatchStatus"
                                    value={filterSubscription.filterDispatchStatus}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                >
                                    <option value="">Select Dispatch Status</option>
                                    <option value="1">Dispatched</option>
                                    <option value="2">Not Dispatched</option>
                                </select>
                            </div>
                            {/* <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Status
                                </label>
                                <select
                                    name="filterStatus"
                                    value={filterSubscription.filterStatus}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}

                            <div className="sm:col-span-1 lg:col-span-1 mt-6">
                                {filterSubscription?.filterMagazineId?.value && filterSubscription?.filterMagazineIssueId?.value ? (<button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilter}
                                >
                                    Search
                                </button>) : (<></>)}
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilterClear}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <div className="flex justify-end">
                <div className="flex items-center space-x-2 hidden">
                    <div className="relative">
                        <button
                            onClick={() => {
                                setFilterFlag(filterFlag ? false : true);
                            }}
                            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2">
                            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                        />
                                    </svg>
                                </div>
                                <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                            </span>
                        </button>
                    </div>
                </div>
                {allowedActions.filter((data) => data.permission_id == 81)?.length > 0 && getSubsIntervalDispatch?.length > 0 ? (<>
                    <Link
                        to={"#"}
                        onClick={() => handleExport('xlsx')}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                        Download Excel
                    </Link> <Link
                        to={"#"}
                        onClick={() => handleDispatch()}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2"
                    >
                        Dispatch
                    </Link></>) : null}
            </div>
            {!isSearched && (<p className='text-center text-xl font-semibold mt-5' style={{ color: "#872341" }}>Select Filter To Display Subscription Dispatch List</p>)}
            {loading ? (<FallingLinesLoader />) : (
                isSearched &&
                <Table
                    columns={subscription_dispatch_columns({
                        onDeleteOpen, onStatusUpdate, handleSaveTrackNoDispatch, setShowPrintAddress,
                        prinData: {
                            printAddressDataHandler: printAddressDataHandler,
                            dataCount: (getSubsIntervalDispatch ? getSubsIntervalDispatch.length : 0)
                        },
                    })}
                    data={getSubsIntervalDispatch}
                    is_toggle={false}
                    printOption={true}
                    printAddressDataHandler={printAddressDataHandler}
                    setShowPrintAddress={setShowPrintAddress}
                />)}

            <PrintAddress open={showPrintAddress} setShowPrintAddress={setShowPrintAddress} printAddressDataHandler={printAddressDataHandler} printAddressData={printAddressData} />
        </div>
    )
}

export default SubsIntervalDispatch