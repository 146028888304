import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { citySchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { Pincode_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";
import SlideOverlay from "../../../components/SlideOverlay";
import * as Yup from "yup";
const API = process.env.REACT_APP_API_URL;
const pincodeschema = Yup.object().shape({
    pincode: Yup.string().required("Pincode is required").max(6, "Pincode should be 6 digit").min(6, "Pincode should be 6 digit"),
})
function Pincode() {
  const pages = [{ title: "Pincode", href: "/pincode", module_id: 25 }];
  const [PincodeList, setPincodeList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [picode_id, setpicode_id] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    Pincode: null,
  });
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    fk_city_id: null, // Because its number type
  });

  const [city, setCity] = useState(null);

  useEffect(() => {
    document.title = "Outlook | Pincode";
    getAllpincode();
    // getAllpincode();

    getAllCitys();
  }, []);

  const [cityList, setCityList] = useState([]);

  const getAllCitys = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/city`);
      console.log("All Citys Data", data);
      if (data.status) {
        setCityList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllpincode = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/pincode`);
      console.log("All Citys Data", data);
      if (data.status) {
        setPincodeList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const initialValues = {
    city_name: "",
    city_id: "",
  };

  const [formCity, setFormCity] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    console.log("Drawer", type, id, obj);
    if (type === "edit" && id) {
      setpicode_id(id);
      const initialValues = {
        city_name: obj.pincode,
        city_id: obj.fk_city_id,
      };
      setFormCity(initialValues);
    } else {
      setpicode_id("");
      setFormCity(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (Pincode) => {
    setConfirmationModal({ Pincode, status: true });
  };

  const onDeleteCity = async (Pincode) => {
    try {
      setIsLoading(true);
            const res = await Api().delete(`/api/dashboard/masters/pincode/${Pincode}`);
      if (res.status) {
        getAllpincode();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues } =
        useFormik({
    enableReinitialize: true,
    initialValues: formCity,
    onSubmit: async (values, action) => {
      console.log("Values", values);
      try {
        setIsLoading(true);
        let body = {
          pincode: values.city_name.toString(),
          fk_city_id: Number(values.city_id),
        };



        if (!!picode_id) {
          const res = await Api().put(
            `/api/dashboard/masters/pincode/${picode_id}`,
            {
              pincode: values.city_name.toString(),
              fk_city_id: Number(values.city_id),
            }
          );
          if (res.status) {
            getAllpincode();
          }
        } else {
          const res = await Api().post(`/api/dashboard/masters/pincode`, body);
          if (res.status) {
            getAllpincode();
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
      action.resetForm();
      if (modalOpenFlage === true) {
        getAllpincode();
        setmodalOpenFlage(false);
      }
    },
  });

  function renderOverLay() {
    return (
      <div>
        <SlideOverlay open={modalOpenFlage} setOpen={setmodalOpenFlage}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">

                <div className="flex items-center justify-between">
                  <label className="text-lg font-medium text-white">
                    {picode_id ? "Update" : "Add"} Pincode
                  </label>
                  <div className="ml-3 flex h-7 items-center">
                    <button
                      type="button"
                      className="rounded-md bg-red-700 text-red-200 hover:text-white"
                      onClick={() => setmodalOpenFlage(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col justify-between">
                <div className="p-4 sm:p-6">
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                    <div>
                      <label
                        htmlFor="city_name"
                        className="block text-sm font-medium text-gray-900"
                      >
                        City Name
                      </label>
                    </div>
                    <select
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                      name="city_id"
                      id="city_id"
                      value={values.city_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={null}> Select City </option>
                      {cityList?.map((s, i) => (
                                                <option
                                                    key={s.city_id}
                                                    value={s.city_id}
                                                >
                          {s.city_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                    <div>
                      <label
                        htmlFor="city_name"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Pincode
                      </label>
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        value={values.city_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={6}

                        type="number"
                        placeholder="Pincode"
                        name="city_name"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                      />
                      {errors.city_name && touched.city_name ? (
                        <p className="text-red-600 text-sm">
                          {errors.city_name}
                        </p>
                      ) : null}
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 justify-end px-4 py-4">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  setmodalOpenFlage(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {picode_id ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </SlideOverlay>
      </div>
    );
  }

  const getAllPincodeByCityId = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(
        `/api/dashboard/masters/pincode?fk_city_id=${city}`
      );
      console.log("All Citys Data", data);
      if (data.status) {
          setPincodeList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (city) {
      getAllPincodeByCityId();
    }
  }, [city]);
  console.log(city);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderOverLay()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCity(confirmationModal.Pincode)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Pincode</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Pincode.</p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
        >
          Add Pincode
        </Link>
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
                    bigFilterArea={<div>
              <select
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                name="fk_city_id"
                id="fk_city_id"
                value={city}
                onChange={(e) => {
                //   console.log("Filter", e.target.value);
                //   setFilter({ ...filter, fk_city_id: e.target.value });
                  setCity(e.target.value);
                }}
              >
                <option value={""}> Select City </option>
                {cityList?.map((s, i) => (
                                <option
                                    key={s.city_id}
                                    value={s.city_id}
                                >
                    {s.city_name}
                  </option>
                ))}
              </select>
                    </div>}
          columns={Pincode_columns({ onDeleteOpen, handleDrawer })}
          data={
            PincodeList?.length > 0 && !isLoading
              ? PincodeList
              : []
          }
          is_toggle={false}
        />
      )}

    </div>
  );
}
export default Pincode;


