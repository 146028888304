import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { RegionSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { Region_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";
import { toast } from "react-toast";

import * as XLSX from "xlsx";
import { handleDynamicFileImport } from '../../../helper/dynamicImport';
import BarcodeServices from "../../../ApiServices/BarcodeServices";
import { FaSpinner } from "react-icons/fa";

function RegionMaster() {
  const pages = [{ title: "Region List", href: "/Region", module_id: 24 }];
  const [RegionList, setRegionList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [Region_id, setRegionid] = useState("");

  const [importLoader, setImportLoader] = useState(false);
  const [importMapLoader, setImportMapLoader] = useState(false);
  const fileInputRef = useRef(null);
  const fileRegionZoneStateInputRef = useRef(null);

  const [error, setError] = useState(null);

  // // Define your entity map for dynamic import (example)
  const entityRegionZoneStateMap = {
    Zone: 'Zone',
    Region_Code: 'Region_Code',
    State_Code: 'State_Code',
    State_Name: 'State_Name',
  };

  // // Define the entity you are working with (in this case 'barcode')
  const entityRegionZoneState = "Zone";

  // Define your entity map for dynamic import (example)
  const entityMap = {
    Region_Code: 'Region_Code',
    Region_Name: 'Region_Name',
  };

  // Define the entity you are working with (in this case 'barcode')
  const entity = "Region_Code";

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };

    BarcodeServices.regionExcelImport(payload, entity)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entity} imported successfully`);
          getAllRegions('');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entity}`);
      });
  };

  const handleRegionZoneStateClick = () => {
    fileRegionZoneStateInputRef.current.click();
  };
  const handleRegionZoneStateImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportMapLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityRegionZoneStateMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importRegionZoneStateEntities(importData);
          } else {
            setImportMapLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportMapLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importRegionZoneStateEntities = (data) => {
    const payload = { excel_data: data };

    BarcodeServices.regionZoneStateExcelImport(payload, entityRegionZoneState)
      .then((response) => {
        setImportMapLoader(false);
        if (response.status === 200) {
          toast.success(`${entityRegionZoneState} imported successfully`);
          getAllRegions('');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportMapLoader(false);
        toast.error(`Error while importing ${entityRegionZoneState}`);
      });
  };

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    Region_id: null,
  });

  const [state, setState] = useState(null);

  const [stateList, setStateList] = useState([]);

  const getAllStates = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/state`);
      console.log("All States Data", data);
      if (data.status) {
        setStateList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Outlook | Regions";
    getAllRegions('');
    getAllZone();
    getAllStates()
  }, []);
  console.log('state=>', state)

  const getAllRegions = async (state_id) => {
    try {
      setIsLoading(true);
      let payload = {
        state_id: state_id,
        start_date: '', // Start date for filtering
        end_date: '', // End date for filtering
        created_by: '', // Filter by created_by user ID
        updated_by: '', // Filter by updated_by user ID
        state_is_active: true, // Filter by active status
        state_delete: false, // Filter by deleted status
        page: 1, // Pagination page number, default is 1
        pageSize: 100, // Pagination limit, default is 10
        isAll: 0,
        isSelectedColumn: 0,
      }
      const queryString = new URLSearchParams(payload).toString();
      const { data } = await Api().get(`/api/dashboard/masters/Region?${queryString}`);
      console.log("All getAllRegions Data", data);
      if (data.status) {
        setRegionList(data?.data?.States);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllZone = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/zone`);
      if (data.status) {
        setZoneList(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    id: "",
    reg_code: "",
    name: "",
    fk_zone_id: "",
  };

  const [formRegion, setFormRegion] = useState(initialValues);

  const handleDrawer = async (type, id, obj) => {
    if (type === "edit" && id) {
      setRegionid(id);
      setFormRegion({
        reg_code: obj?.RegionMaster?.reg_code,
        name: obj?.RegionMaster?.name,
        fk_zone_id: obj?.RegionMaster?.fk_zone_id,
      });
    } else {
      setRegionid("");
      setFormRegion(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (Region_id) => {
    setConfirmationModal({ Region_id, status: true });
  };

  const onDeleteRegion = async (Region_id) => {
    try {
      const res = await Api().delete(
        `/api/dashboard/masters/region/${Region_id}`
      );
      if (res.status) {
        getAllRegions('');
      }
      setConfirmationModal({ Region_id: null, status: false });
    } catch (error) {
      console.log(error);
      setConfirmationModal({ Region_id: null, status: false });
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formRegion,
      //   validationSchema: RegionSchema,
      onSubmit: async (values, action) => {
        try {
          setIsLoading(true);
          let body = {
            reg_code: values.reg_code,
            name: values.name,
            fk_zone_id: Number(values.fk_zone_id),
          };

          if (Region_id) {
            const res = await Api().put(
              `/api/dashboard/masters/region/${Region_id}`,
              body
            );
            if (res.status) {
              getAllRegions('');
            }
          } else {
            const res = await Api().post(`/api/dashboard/masters/region`, body);
            if (res.status) {
              getAllRegions('');
            }
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        action.resetForm();
        if (modalOpenFlage === true) {
          getAllRegions('');
          setmodalOpenFlage(false);
        }
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteRegion(confirmationModal.Region_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Region</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Regions.</p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
        >
          Add Region
        </Link>
        <button
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-1"
          disabled={importLoader}
          onClick={handleButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Region"
          )}
        </button>
        <input
          accept=".xlsx, .xls"
          onChange={handleImport}
          type="file"
          name="barcode_excel"
          autoComplete="off"
          ref={fileInputRef}
          className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
          style={{ display: 'none' }} // Hide the file input
        />

        <button
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-1"
          disabled={importMapLoader}
          onClick={handleRegionZoneStateClick}
          aria-live="polite" // Optional for screen readers
        >
          {importMapLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Region Zone State Map"
          )}
        </button>
        <input
          accept=".xlsx, .xls"
          onChange={handleRegionZoneStateImport}
          type="file"
          name="barcode_excel"
          autoComplete="off"
          ref={fileRegionZoneStateInputRef}
          className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
          style={{ display: 'none' }} // Hide the file input
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          bigFilterArea={
            <div>
              <select
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                name="fk_state_id"
                id="fk_state_id"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  getAllRegions(e.target.value);
                }}
              >
                <option value={""}>Select State</option>
                {stateList?.map((s, i) => (
                  <option key={s.state_id} value={s.state_id}>
                    {s.state_name}
                  </option>
                ))}
              </select>
            </div>
          }
          columns={Region_columns({ onDeleteOpen, handleDrawer })}
          data={RegionList?.length > 0 && !isLoading ? RegionList : []}
          is_toggle={false}
        />
      )}

      {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={handleSubmit}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {Region_id ? "Update" : "Add"} Region
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-red-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="reg_code"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Region Code
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.reg_code}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Region Code"
                                      name="reg_code"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Region name
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Region Name"
                                      name="name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mt-1 sm:col-span-2 sm:mt-0 mx-6 mb-2">
                              <div>
                                <label
                                  htmlFor="fk_state_id"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Zone
                                </label>
                              </div>
                              <select
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                                name="fk_zone_id"
                                id="fk_zone_id"
                                value={values.fk_zone_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value={null}> Select Zone </option>
                                {zoneList?.map((s, i) => (
                                  <option key={s.id} value={s.id}>
                                    {s.zone_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                              {Region_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default RegionMaster;
