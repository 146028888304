import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import PrintAddressTable from "./PrintAddressTable";
import { useEffect } from "react";

export default function PrintAddress(props) {
  const {
    open,
    setShowPrintAddress,    
  } = props;

// const [printAddressData, setPrintAddressData] = useState(props.printAddressData || [])
  // console.log(printAddressData);

  // useEffect(()=>{
  //   setPrintAddressData(props.printAddressData)
  // },[props.printAddressData])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          setShowPrintAddress(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="rounded-lg overflow-hidden transform transition-all sm:max-w-5xl sm:w-full" style={{background : "wheat"}}>
              <div className="p-6">
                <div className="flex justify-end">                  
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="text-black-800 hover:text-black-800"
                      onClick={() => {
                        setShowPrintAddress(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>  
                <PrintAddressTable printAddressData={props.printAddressData || []}/>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
