/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";

export default {

  async saveGst(payload) {
    const response = await Api().post(`api/dashboard/gst/`, payload);
    return response;
  },

  async getAllGst(payload) {
    // Dynamically build the query string
    const queryParams = new URLSearchParams();

    if (payload?.status_code) queryParams.append("search", payload.status_code);
    if (payload?.start_date) queryParams.append("start_date", payload.start_date);
    if (payload?.end_date) queryParams.append("end_date", payload.end_date);
    if (payload?.created_by) queryParams.append("created_by", payload.created_by);
    if (payload?.updated_by) queryParams.append("updated_by", payload.updated_by);
    if (payload?.gst_is_active !== undefined) queryParams.append("gst_is_active", payload.gst_is_active);
    if (payload?.gst_is_deleted !== undefined) queryParams.append("gst_is_deleted", payload.gst_is_deleted);
    if (payload?.page) queryParams.append("page", payload.page);
    if (payload?.pageSize) queryParams.append("pageSize", payload.pageSize);
    if (payload?.isAll !== undefined) queryParams.append("isAll", payload.isAll);
    if (payload?.isSelectedColumn !== undefined) queryParams.append("isSelectedColumn", payload.isSelectedColumn);

    const queryString = queryParams.toString();
    const response = await Api().get(`api/dashboard/gst/?${queryString}`);
    return response;
  },
  async getSingleGst(payload) {
    const response = await Api().get(`api/dashboard/gst/${payload}`);
    return response;
  },
  async RemoveGst(payload) {
    const response = await Api().post(`api/dashboard/gst/remove`, payload);
    return response;
  },
  async updateGstStatus(payload) {
    const response = await Api().post(`api/dashboard/gst/status/`, payload);
    return response;
  },

  async gstExcelImport(payload) {
    const response = await Api().post(`api/dashboard/gst/import`, payload);
    return response;
  },
  

};
