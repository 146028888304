import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Formik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import ConfirmationModal from "../../../components/ConfirmationModal";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { productSchema } from "../../../schemas";
import { product_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import makeAnimated from "react-select/animated";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import Select from "react-select";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Product = () => {
  const pages = [{ title: "Product", href: "/product" }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [poster, setPoster] = useState(null);
  const [tempPoster, setTempPoster] = useState(null);
  const [base64poster, setBase64poster] = useState(null);
  const [enabled, setEnabled] = useState(true);
  const [productId, setproductId] = useState("");
  const [allproduct, setAllProduct] = useState([])
  const [imageBaseURL, setImageBaseURL] = useState(null)
  const [selectedOption, setSelectedOption] = useState({
    value: "PRODUCT", label: "PRODUCT",
  });
  const animatedComponents = makeAnimated();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    product_id: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const navigate = useNavigate();
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Outlook | Product";
    setTempPoster(null);
    getAllProducts()
  }, []);

  const getAllProducts = async () => {
    setLoading(true)
    const response = await MasterServices.getAllProduct(3);
    if (response.data.status == true) {
      if (response.data.data.data.length > 0) {
        setAllProduct(response.data.data.data);
        setImageBaseURL(response.data.data.image_base_url);
      } else {
        setAllProduct([]);
      }
      setLoading(false)
    }
  }

  const onStatusUpdate = (product_id, product_status) => {
    let payload = {
      table_name: "products",
      table_field: "product_id",
      table_field_val: product_id,
      table_status_field: "product_status",
      table_status_val: product_status ? false : true,
      table_text: "Product",
      deleted_by_key: "product_updated_by",
      delete_active_txt: product_status ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllProducts()
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  const initialValues = {
    product_id: "",
    product_name: "",
    product_qty: "",
    product_type: {},
    product_price: "",
    product_discount: "",
    product_no_of_issue: "",
    product_image: "",
    product_status: "",
  };

  const [formProduct, setformProduct] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setTempPoster(null);
    console.log("obj", obj)
    if ((type === "edit", id)) {
      setproductId(id);
      setSelectedOption({
        value: obj.product_type, label: obj.product_type,
      })
      setTempPoster(imageBaseURL ? imageBaseURL + obj?.product_image : null)
      const initialValues = {
        product_id: obj.product_id,
        product_name: obj.product_name,
        product_qty: obj.product_qty,
        // product_type: obj.product_type,
        product_price: obj.product_price,
        product_discount: obj.product_discount,
        product_no_of_issue: obj.product_no_of_issue,
        product_image: obj.product_image,
        product_status: obj.product_status
      };
      setformProduct(initialValues);
    } else {
      setproductId("");
      setPoster(null);
      setSelectedOption({
        value: "PRODUCT", label: "PRODUCT",
      })
      setformProduct(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setPoster(file);
      setTempPoster(e.target.result);
      setBase64poster(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formProduct,
      validationSchema: productSchema,
      onSubmit: async (values, action) => {
        let body = {
          product_id: productId ? productId : 0,
          product_name: values.product_name,
          product_qty: values.product_qty,
          product_type: selectedOption,
          product_price: values.product_price,
          product_discount: values.product_discount,
          product_no_of_issue: values.product_no_of_issue,
          product_image: base64poster,
          product_status: enabled,
        };

        if (body.product_name != undefined || body.product_name != null || body.product_name != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          const response = await MasterServices.saveProductData(body);

          if (response.data.status) {
            action.resetForm();
            if (modalOpenFlage === true) {
              setTempPoster(null);
              getAllProducts()
              setSelectedOption({
                value: "PRODUCT", label: "PRODUCT",
              })
              toast.success(response.data.message);
              setproductId("");
              setDisableSubmitButton(false)
              setmodalOpenFlage(false);
            }
          } else {
            toast.error(response.data.message);
            setDisableSubmitButton(false)
          }
        }
      },
    });

  const onDeleteOpen = (product_id) => {
    setConfirmationModal({ product_id, status: true });
  };

  const onDeleteProduct = (product_id) => {
    // let payload = {
    //   table_name: "products",
    //   table_field: "product_id",
    //   table_field_val: product_id,
    //   table_status_field: "product_deleted",
    //   table_status_val: true,
    //   table_text: "Product",
    //   deleted_by_key: "product_updated_by"
    // }
    // MasterServices.removeMaster(payload).then((res) => {
    //   const { data, status, message } = res.data
    //   if (status) {
    //     getAllProducts()
    //     toast.success(message);
    //   } else {
    //     toast.error(message)
    //   }
    // })

    MasterServices.deleteProd({ product_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ product_id: null, status: false });
          getAllProducts()
          navigate("/product");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });

  }

  const productType = ["MAGAZINE", "PRODUCT"];
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteProduct(confirmationModal.product_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Product List</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Product.</p>
      </div>
      <div className="mt-4 mb-4 flex">
        {allowedActions.filter((data) => data.permission_id == 17)?.length > 0 ? (<Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Add Product
        </Link>) : null}
      </div>

      {!loading ? (
        <Table
          columns={product_columns({ onDeleteOpen, handleDrawer, imageBaseURL, onStatusUpdate, allowedActions })}
          data={allproduct}
          is_toggle={false}
        />

      ) : (
        <FallingLinesLoader />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {/* {role_id ? "Update" : "Add"} */} Product
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#BE3144] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between ">
                            <div className="p-4 sm:p-6 space-y-4">
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="product_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Name<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.product_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Name"
                                    name="product_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_name &&
                                    touched.product_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="my-2  sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                    Select Type
                                  </label>
                                </div>
                                <Select
                                  name="product_type"
                                  placeholder={
                                    <span>
                                      Select type
                                      <span className="text-red-600">*</span>
                                    </span>
                                  }
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  components={animatedComponents}
                                  options={
                                    productType &&
                                    productType?.map((v) => ({
                                      value: v.toLocaleUpperCase(),
                                      label: v.toLocaleUpperCase(),
                                    }))
                                  }
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                />

                                {errors.product_type && touched.product_type ? (
                                  <div className="text-sm text-red-600">
                                    {errors.product_type}
                                  </div>
                                ) : null}
                              </div> */}
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="product_price"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Price<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.product_price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    // min={1}
                                    placeholder="Price"
                                    name="product_price"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_price &&
                                    touched.product_price ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_price}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="product_qty"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    QTY<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.product_qty}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    // min={1}
                                    placeholder="QTY"
                                    name="product_qty"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_qty &&
                                    touched.product_qty ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_qty}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="product_discount"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Discount
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.product_discount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    min={1}
                                    placeholder="Discount"
                                    name="product_discount"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_discount &&
                                    touched.product_discount ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_discount}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              {/* <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="product_no_of_issue"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    No of issue
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.product_no_of_issue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    min={1}
                                    placeholder="Discount"
                                    name="product_no_of_issue"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_no_of_issue &&
                                    touched.product_no_of_issue ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_no_of_issue}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 overflow-auto">
                                  <label
                                    htmlFor="product_image"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Poster<span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                      onFileChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    type="file"
                                    name="product_image"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.product_image &&
                                    touched.product_image ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.product_image}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-span-full">
                                  {tempPoster ? (
                                    <img
                                      className="shadow-sm mt-4 w-40"
                                      src={tempPoster}
                                      alt="preview-image"
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0 ">
                                <div>
                                  <label
                                    htmlFor="product_is_active"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Status
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                      enabled ? "bg-[#872341]" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    )}
                                  >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabled
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                          disabled={disableSubmitButton}
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gradient-to-b focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2"
                          >
                            {productId ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
                            {/* {role_id ? "Update" : "Add"} Add */}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default Product;
