import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Select from "react-select";
import { useFormik } from "formik";
import MasterServices from "../../../ApiServices/MasterServices";
import makeAnimated from "react-select/animated";
import { toast } from "react-toast";
import { gstMasterSchema } from "../../../schemas";
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { BsCurrencyRupee } from "react-icons/bs";
import moment from "moment";
import GSTServices from "../../../ApiServices/GSTServices";

const AddGst = () => {
  const { gst_id: gst_id } = useParams();
  const pages = [
    {
      title: gst_id ? "Update GST" : "Add GST",
      href: "/add-gst",
      module_id: 12,
    },
  ];

  const [loading, setLoading] = useState(false);
  const gstType = [
    {
      label: "PHYSICAL",
      value: "0",
    },
    {
      label: "DIGITAL",
      value: "1",
    },
  ];
  const [selectedGstType, setSelectedGstType] = useState([]);

  const [magazineList, setMagazineList] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState([]);

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const initialValues = {
    gst_id: "",
    gst_name: "",
    gst_doc_type: "",
    gst_rate: "",
    gst_magazine: "",
    gst_product: "",
    fk_mm_id: "",
    fk_product_id: "",
  };

  const [formGst, setFormGst] = useState(initialValues);

  const getSingleGst = async (gst_id) => {
    setLoading(true);
    GSTServices.getSingleGst(gst_id)
      .then(async (res) => {
        const { data, status } = res.data;
        if (status) {
          
          let getGSTType = gstType.filter((item) => item.gst_doc_type == data?.gst_doc_type)
          setSelectedGstType(getGSTType?.length>0?getGSTType[0]:{
            label: data?.gst_doc_type,
            value: data?.gst_doc_type,
          });
          let getMMList = magazineList.filter((item) => item.mm_id == data?.fk_mm_id)
          setSelectedMagazine({
            label: getMMList?.length>0?getMMList[0]?.mm_name:data?.gst_magazine,
            value: getMMList?.length>0?getMMList[0]?.mm_id:data?.fk_mm_id,
          });
          let getProduct = productList.filter((item) => item.product_id == data?.fk_product_id)
          setSelectedProduct({
            label: getProduct?.length>0?getProduct[0]?.product_name:data?.gst_product,
            value: getProduct?.length>0?getProduct[0]?.product_id:data?.fk_product_id,
          });

          let editedValues = {
            gst_id: gst_id,
            gst_name: data?.gst_name,
            gst_doc_type: data?.gst_doc_type,
            gst_rate: data?.gst_rate,
            gst_magazine: data?.gst_magazine,
            fk_mm_id: data?.fk_mm_id,
            gst_product: data?.gst_product,
            fk_product_id: data?.fk_product_id,
          };
          setFormGst(editedValues);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formGst,
      validationSchema: gstMasterSchema,
      onSubmit: async (values, action) => {
        let body = {
          gst_id: gst_id ? gst_id : "",
          gst_name: values.gst_name,
          gst_doc_type: selectedGstType?.value ? selectedGstType?.value : "",
          gst_rate: values.gst_rate,
          gst_magazine: selectedMagazine.label,
          fk_mm_id: selectedMagazine.value,
          gst_product: selectedProduct.label,
          fk_product_id: selectedProduct.value,
        };

        if (!selectedGstType?.label) {
          toast.error("Please Select GST Type");
          return false;
        }
        if (!selectedMagazine?.label && !selectedProduct?.label) {
          toast.error("Please Select either GST Magazine or GST Product");
          return false;
        }

        console.log("body", body);
        // return false;
        setTimeout(() => {
          setDisableSubmitButton(false);
        }, 1000);
        setDisableSubmitButton(true);
        const response = await GSTServices.saveGst(body);
        if (response.data.status) {
          action.resetForm();
          setFormGst(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false);
          navigate("/gst-master");
        } else {
          setDisableSubmitButton(false);
          toast.error(response.data.message);
        }
      },
    });
  console.log("errors=>", errors);
  const handleGstType = (e) => {
    setSelectedGstType(e);
    console.log("setSelectedVoucherType", e);
  };

  const getAllMagazines = () => {
    MasterServices.getAllMagazine(1)
      .then((res) => {
        const { data, status } = res.data;
        if (status) {
          const newArray = data?.data?.map((ele) => ({
            label: ele.mm_name,
            value: ele.mm_id,
          }));
          setMagazineList(newArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMagazine = (e) => {
    setSelectedMagazine(e);
    setSelectedProduct([]);
  };

  const getAllProducts = () => {
    MasterServices.getAllProduct(1)
      .then((res) => {
        const { data, status } = res.data;
        if (status) {
          const newArray = data?.data?.map((ele) => ({
            ...ele,
            value: ele.product_id,
            label: ele.product_name,
          }));
          setProductList(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleProduct = (e) => {
    setSelectedProduct(e);
    setSelectedMagazine([]);
  };

  useEffect(() => {
    getAllMagazines();
    getAllProducts();
    if (gst_id) {
      getSingleGst(gst_id);
    }
  }, []);
  return (
    <>
      {!loading ? (
        <div style={{minHeight : "70vh"}}>
          <Breadcrumb pages={pages} />
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">
              {gst_id ? "Update" : "Add"} GST
            </h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-4 lg:grid-cols-3 lx:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="gst_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  GST Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="gst_name"
                    id="gst_name"
                    value={values.gst_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="GST Name"
                  />
                  {errors.gst_name && touched.gst_name ? (
                    <div className="text-sm text-red-600">
                      {errors.gst_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="gst_doc_type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Type
                </label>
                <div className="">
                  <Select
                    className=" w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                    placeholder="Select Type"
                    name="gst_doc_type"
                    onChange={(e) => handleGstType(e)}
                    options={gstType}
                    value={selectedGstType}
                    onBlur={handleBlur}
                  />
                  {errors.gst_doc_type && touched.gst_doc_type ? (
                    <div className="text-sm text-red-600">
                      {errors.gst_doc_type}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="gst_magazine"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Select Magazine
                </label>
                <div className="">
                  <Select
                    isDisabled={selectedProduct && selectedProduct.length !== 0}
                    className=" w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                    placeholder="Select Magazine"
                    name="gst_magazine"
                    onChange={(e) => handleMagazine(e)}
                    options={magazineList}
                    value={selectedMagazine}
                    onBlur={handleBlur}
                    isClearable={true}
                  />
                  {errors.gst_magazine && touched.gst_magazine ? (
                    <div className="text-sm text-red-600">
                      {errors.gst_magazine}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="gst_product"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Select Product
                </label>
                <div className="">
                  <Select
                    isDisabled={
                      selectedMagazine && selectedMagazine.length !== 0
                    }
                    className=" w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                    placeholder="Select Product"
                    name="gst_product"
                    onChange={(e) => handleProduct(e)}
                    options={productList}
                    value={selectedProduct}
                    onBlur={handleBlur}
                    isClearable={true}
                  />
                  {errors.gst_product && touched.gst_product ? (
                    <div className="text-sm text-red-600">
                      {errors.gst_product}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="gst_rate"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  GST Rate<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="gst_rate"
                    id="gst_rate"
                    value={values.gst_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="GST Rate"
                  />
                  {errors.gst_rate && touched.gst_rate ? (
                    <div className="text-sm text-red-600">
                      {errors.gst_rate}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                    </button> */}
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                {gst_id ? (
                  disableSubmitButton ? (
                    <FaSpinner color="gold" />
                  ) : (
                    "Update"
                  )
                ) : disableSubmitButton ? (
                  <FaSpinner color="gold" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <FallingLinesLoader />
      )}
    </>
  );
};

export default AddGst;
