import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Table from "../../../components/tables/table";
import { gst_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";
import { gstMasterSchema } from "../../../schemas";
import MasterServices from "../../../ApiServices/MasterServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { toast, ToastContainer } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { useNavigate, useParams } from 'react-router-dom';
import GSTServices from "../../../ApiServices/GSTServices";

function Gst() {
  const pages = [{ title: "GST Master", href: "/gst-master", module_id: 26 }];
  const [gstList, setGstList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [gst_id, setGstid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    gst_id: null,
  });
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const animatedComponents = makeAnimated();

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  const navigate = useNavigate();


  console.log(selectedState, selectedCountry);

  const [gst_type, setGst_type] = useState([
    {
      label: "Physical",
      value: "physical",
    },
    {
      label: "Digital",
      value: "digital",
    },
  ]);

  const [selectedGstType, setSelectedGstType] = useState([]);

  const handleGstType = (e) => {
    setSelectedGstType(e);
  };

  const [magazineAll, setMagazineAll] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState([]);

  const getAllMagazines = () => {
    MasterServices.getAllMagazine(1)
      .then((res) => {
        const { data, status } = res.data;
        if (status) {
          const newArray = data?.data?.map((ele) => ({
            label: ele.mm_name,
            value: ele.mm_id,
          }));
          setMagazineAll(newArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [allProduct, setAllProduct] = useState([]);

  const getAllProducts = () => {
    MasterServices.getAllProduct(1)
      .then((res) => {
        const { data, status } = res.data;
        if (status) {
          const newArray = data?.data?.map((ele) => ({
            ...ele,
            value: ele.product_id,
            label: ele.product_name,
          }));
          setAllProduct(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMagazine = (e) => {
    setSelectedMagazine(e);
    setSelectedProduct([]);
  };

  console.log(selectedMagazine);
  const handleProduct = (e) => {
    setSelectedProduct(e);
    setSelectedMagazine([]);
  };

  useEffect(() => {
    getAllCountryList();
    getAllMagazines();
    getAllProducts();
    getAllGstListData();
  }, []);

  const getAllStates = async (country_id) => {
    try {
      setIsLoading(true);
      MasterServices.getStateByCountry(country_id)
        .then((res) => {
          const { data, status } = res;
          if (status) {
            let newState = data.data?.map((v) => ({
              label: v.state_name,
              value: v.state_id,
            }));
            setStateList(newState);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllGstListData = async () => {
    try {
      setIsLoading(true);
      let payload={
        status_code:3,
        start_date: '', // Start date for filtering
        end_date: '', // End date for filtering
        created_by: '', // Filter by created_by user ID
        updated_by: '', // Filter by updated_by user ID
        state_is_active: true, // Filter by active status
        state_delete: false, // Filter by deleted status
        page: 1, // Pagination page number, default is 1
        pageSize: 1000, // Pagination limit, default is 10
        isAll: 0,
        isSelectedColumn: 0,
      }
      const { data } = await GSTServices.getAllGst(payload);
      // const { data } = await Api().get(`/api/dashboard/gst?search=1`);
      if (data.status) {
        setGstList(data.data.gstRecords);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCountryList = () => {
    MasterServices.getAllCountry(1)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          setCountryList(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCountry = (e) => {
    setSelectedCountry(e);
    getAllStates(e?.country_id);
  };

  const handleState = (e) => {
    setSelectedState(e);
  };

  let initialValues = {
    gst_id: "",
    gst_name: "",
    gst_type: "",
    gst_rate: "",
    gst_country: "",
    gst_state: "",
    fk_state_id: "",
    fk_country_id: "",
  };

  const [formGst, setFormGst] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if (type === "edit" && id) {
      setGstid(id);
      const initialValues = {
        gst_id: id,
        gst_name: obj.gst_name,
        gst_type: obj.gst_type,
        gst_pincode: obj.gst_pincode,
        gst_rate: obj.gst_rate,
        gst_state: obj.gst_state,
        fk_state_id: obj.fk_state_id,
        fk_country_id: obj.fk_country_id,
      };
      setSelectedCountry({
        label: obj?.m_country_relation?.country_name,
        value: obj?.fk_country_id,
      });
      setSelectedState({
        label: obj?.m_state_relation?.state_name,
        value: obj?.fk_state_id,
      });
      setFormGst(initialValues);
    } else {
      setGstid(0);
      setFormGst(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (gst_id) => {
    setConfirmationModal({ gst_id, status: true });
  };

  const onDeleteGst = async (gst_id) => {
    try {
      setIsLoading(true);
      let body = {
        gst_id: gst_id,
      };

      const res = await GSTServices.RemoveGst(body);
      // const res = await Api().post(`/api/dashboard/gst/remove`, body);
      if (res.status) {
        getAllGstListData();
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setConfirmationModal({ status: false, gst_id: null });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formGst,
      validationSchema: gstMasterSchema,
      onSubmit: async (values, action) => {
        try {
          setIsLoading(true);
          let body = {
            gst_id: gst_id ? gst_id : 0,
            gst_name: values.gst_name,
            gst_type: selectedGstType.value,
            gst_rate: values.gst_rate,
            gst_country: selectedCountry?.label,
            gst_state: selectedState?.label,
            fk_country_id: selectedCountry?.value,
            fk_state_id: selectedState?.value,
          };
          console.log(body);

          if (gst_id) {
            const res = await Api().post(`/api/dashboard/gst`, body);
            if (res.status) {
              toast.success(res?.data?.message);
              getAllGstListData();
            }
          } else {
            const res = await Api().post(`/api/dashboard/gst`, body);
            if (res.status) {
              toast.success(res?.data?.message);
              getAllGstListData();
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Something Went Wrong");
          setIsLoading(false);
        }
        action.resetForm();
        if (modalOpenFlage === true) {
          getAllGstListData();
          setmodalOpenFlage(false);
        }
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteGst(confirmationModal.gst_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">GST Master</h1>
      </div>
      <div className="mt-4 flex">
        {/* {allowedActions.filter((data) => data.permission_id == 44)?.length >
        0 ? ( */}
          <Link
            to={"/add-gst"}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
          >
            Add GST
          </Link>
        {/* ) : null} */}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={gst_columns({
            onDeleteOpen,
             allowedActions 
          })}
          data={gstList?.length > 0 && !isLoading ? gstList : []}
          is_toggle={false}
        />
      )}

      {/* {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {gst_id ? "Update" : "Add"} GST
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-red-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="gst_name"
                                      className="text-left block text-sm font-medium text-gray-900"
                                    >
                                      GST Name
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.gst_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter GST Name"
                                      name="gst_name"
                                      autoComplete="off"
                                      className="block w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:text-sm"
                                      //   required
                                    />
                                    {errors.gst_name && touched.gst_name ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.gst_name.replace(
                                          "gst_name",
                                          "GST Name"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="gst_type"
                                      className="text-left block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      GST Type
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="gst_type"
                                      placeholder={
                                        <span>
                                          Select Type
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => {
                                        values.gst_type = e.value;
                                        handleGstType(e);
                                      }}
                                      value={selectedGstType}
                                      components={animatedComponents}
                                      options={gst_type}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="gst_rate"
                                      className="text-left block text-sm font-medium text-gray-900"
                                    >
                                      GST Rate{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.gst_rate}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter GST Rate"
                                      name="gst_rate"
                                      autoComplete="off"
                                      className="block w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:text-sm"
                                    />
                                    {errors.gst_rate && touched.gst_rate ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.gst_rate.replace(
                                          "gst_rate",
                                          "GST Rate"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="gst_magazine"
                                      className="text-left block text-sm font-medium text-gray-900"
                                    >
                                      Select Magazine{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      isDisabled={
                                        selectedProduct &&
                                        selectedProduct.length !== 0
                                      }
                                      name={`gst_magazine`}
                                      placeholder={
                                        <span>
                                          Select Magazine
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => handleMagazine(e)}
                                      components={animatedComponents}
                                      value={selectedMagazine}
                                      options={magazineAll}
                                      isClearable={true}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full shadow-sm focus:border-[1px] focus:border-red-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="gst_product"
                                      className="text-left block text-sm font-medium text-gray-900"
                                    >
                                      Select Product{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      isDisabled={
                                        selectedMagazine &&
                                        selectedMagazine.length !== 0
                                      }
                                      name={`gst_product`}
                                      placeholder={
                                        <span>
                                          Select Product
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => handleProduct(e)}
                                      components={animatedComponents}
                                      value={selectedProduct}
                                      options={allProduct}
                                      isClearable={true}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full shadow-sm focus:border-[1px] focus:border-red-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 h-[50%] mt-auto"
                                onClick={() => {
                                  setmodalOpenFlage(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 h-[50%] mt-auto"
                              >
                                {gst_id ? "Update" : "Add"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )} */}
    </div>
  );
}
export default Gst;
