/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";

export default {

  async getAllSubscriptionReport(payload) {
    const response = await Api().get(`api/dashboard/report/?search=${payload}`);
    return response;
  },

  async getAllIssuesReport(payload) {
    const response = await Api().get(`api/dashboard/report/issues-report/?search=${payload?.status_code}`);
    return response;
  },
  async getAllDashboardStat(payload) {
    const response = await Api().get(`api/dashboard/dashboard/?search=${payload}`);
    return response;
  },
  async getAllRegionWiseReport(status_code) {
    let filters = `&filterIssueDate=&filterMagazineName=&filterMagazineId=&filterMagazineIssueId=`;
    if (status_code?.filterRegion?.filterFlag) {
      let filterRegion = status_code?.filterRegion;
      filters = '';
      if (filterRegion?.filterIssueDate) {
        filters += `&filterIssueDate=${filterRegion?.filterIssueDate}`;
      } else {
        filters += `&filterIssueDate=`;
      }

      if (filterRegion?.filterMagazineId?.label) {
        filters += `&filterMagazineName=${filterRegion?.filterMagazineId?.label}`;
      } else {
        filters += `&filterMagazineName=`;
      }
      if (filterRegion?.filterMagazineId?.value) {
        filters += `&filterMagazineId=${filterRegion?.filterMagazineId?.value}`;
      } else {
        filters += `&filterMagazineId=`;
      }
      
      if (filterRegion?.filterMagazineIssueId?.value) {
        filters += `&filterMagazineIssueId=${filterRegion?.filterMagazineIssueId?.value}`;
      } else {
        filters += `&filterMagazineIssueId=`;
      }
    }

    const response = await Api().get(`api/dashboard/report/region/dispatch-report/?search=${status_code.status_code}${filters}`);
    return response;
  },

};
